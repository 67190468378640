import './styles.sass'
import { useState } from 'react'
import { Certificate } from 'crypto-pro'
import ModalSigning from 'ui/ModalSigning'
import GetOrganizationList from '../ActWizard/GetOrganizationList'
import AuthCertificateSelect from '../ActWizard/AuthCertificateSelect'
import AuthProcess from '../ActWizard/AuthProcess'
import { Organization } from 'api/diadoc'

export interface Props {
  open: boolean
  onClose: () => void
  onAuthorized: () => void
}

export default function DispatcherDiadocSyncWizard ({ open, onClose, onAuthorized }: Props) {
  const [authorized, setAuthorized] = useState(false) // has a Diadoc token
  const [certificate, setCertificate] = useState<Certificate>()

  return (
    <>
      { open && <ModalSigning
        open={open}
        onClose={onClose}
        data={{
          authFirstStep: 1,
          authorized,
          certificate,
          onAuthorized: (b: boolean) => {
            setAuthorized(b)

            if (!b) {
              setCertificate(undefined)
            }
          },
          onOrganizationListLoaded: (list?: Organization[]) => {
            if (list && list.length) {
              onAuthorized()
              onClose()
            }
          },
          onCertificateSelected: setCertificate
        }}
        steps={[
          {
            title: 'Загружаем список организаций',
            fn: GetOrganizationList,
            cancel: 'Отменить',
            next: 'Далее'
          },
          {
            title: 'Выбор сертификата',
            fn: AuthCertificateSelect,
            cancel: 'Отменить',
            next: certificate ? 'Далее' : null
          },
          {
            title: 'Авторизация в Diadoc',
            fn: AuthProcess
          },
          {
            title: 'Загружаем список организаций',
            fn: GetOrganizationList
          }
        ]}
      /> }
    </>
  )
}
